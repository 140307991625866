
import {defineComponent, ref} from "vue";
import {Field, Form, ErrorMessage} from "vee-validate";
import * as Yup from "yup";
import UserService from "@/core/services/UserService";

export default defineComponent({
  components: {Field, Form, ErrorMessage},
  name: "SignUp",
  setup: function () {
    const state = ref({email: '', inviteCode:''});
    const error = ref(false);
    const registered = ref({status: false, message: '', registered: false})
    const validate = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    return {
      validate,
      state,
      registered,
      error,
    }
  },
  methods: {
    formSubmit() {
      this.error = false;
      const submitButton = this.$refs.submitButton as typeof HTMLButtonElement.prototype;
      submitButton.disabled = true
      submitButton?.setAttribute("data-kt-indicator", "on");
      UserService.preRegister(this.state).then(status => {
        this.registered.registered = status.success;
        this.registered.status = status.success;
        this.error = !status.success;
        this.registered.message = status.message;
      }).catch(eror => {
        const finalError = {};
        eror.data.errors.forEach(item => {
          finalError[item.field] = [item.message]
        })
        const form = this.$refs.registerForm as typeof HTMLFormElement.prototype
        form.setErrors(finalError);
      }).finally(() => {
        submitButton.disabled = false;
        submitButton?.removeAttribute("data-kt-indicator");
      })
    }
  }
})
